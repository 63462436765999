"use strict";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

import smoothscroll from 'smoothscroll-polyfill';

// kick off the polyfill!
smoothscroll.polyfill();

//---------------------------------------------
// Load JQuery - Bootstrap 5 uses Vanilla JS
//---------------------------------------------
// import $ from "jquery";

// Instead: import just what we need from Bootstrap JS

// This allows me to access the needed components in the defined
// imports' objects (ex: the Modal object so I can trigger via JS)

window.bootstrap = require("bootstrap/dist/js/bootstrap.bundle.js");

// import 'bootstrap/js/dist/alert';
// import Button from "bootstrap/js/dist/button";
// import Carousel from "bootstrap/js/dist/carousel";
// import Collapse from "bootstrap/js/dist/collapse";
// import Dropdown from "bootstrap/js/dist/dropdown";
// import "bootstrap/js/dist/modal";
// import Offcanvas from "bootstrap/js/dist/offcanvas";
// import 'bootstrap/js/dist/popover';
// import "bootstrap/js/dist/scrollspy";
// import "bootstrap/js/dist/tab";
// import 'bootstrap/js/dist/toast';
// import "bootstrap/js/dist/tooltip";

//---------------------------------------------
// Load all styles
//---------------------------------------------
import "../scss/main.scss";

// ====================================================================
// Variables
// ====================================================================

// For window resizing
let timeout = false, // holder for timeout id
  delay = 250, // delay after event is "complete" to run callback
  calls = 0;

let mobile_check = false;

const bodyEl = document.querySelector("body");

const slideIndicatorBtnsDesktop = document.querySelectorAll(
  ".carousel-indicators.top.desktop button"
);
const slideIndicatorBtnsMobile = document.querySelectorAll(
  ".carousel-indicators.top.mobile button"
);
const thumbnailNavBtnsDesktop = document.querySelectorAll(
  "#thumbnail-nav.desktop button"
);
const thumbnailNavBtnsMobile = document.querySelectorAll(
  "#thumbnail-nav.mobile button"
);

const myCarouselElDesktop = document.querySelector(
  ".desktop-carousel #carouselImagesDesktop"
);
const myCarouselElMobile = document.querySelector(
  ".mobile-carousel #carouselImagesMobile"
);
const offCanvasMenuDesktop = document.querySelector("#offcanvasMenuDesktop");
const offCanvasMenuMobile = document.querySelector("#offcanvasMenuMobile");

const mobileContent = document.querySelectorAll(".mobile-carousel");
const desktopContent = document.querySelectorAll(".desktop-carousel");

const viewToggleSwitches = document.querySelectorAll("label.switch input");
const viewToggleSwitchContainer = document.querySelector("#toggle-view-switch");
let isToggledToMobile = false;

// Hide the mobile/desktop toggle if there's no mobile to display
if (
  !document.querySelector(
    "#carouselImagesMobile .carousel-inner .carousel-item"
  ) &&
  window.location.pathname != "/"
) {
  viewToggleSwitchContainer.style.display = "none";
}

//---------------------------------------------
// Nav scrolling animation - hides on scroll down,
// reveal on scroll up
//---------------------------------------------
function initNavAnimations() { 
  const showAnim = gsap.from('nav', { 
    yPercent: -100,
    paused: true,
    duration: 0.1,
  }).progress(1);

  ScrollTrigger.create({
    start: "top top",
    end: 99999,
    onUpdate: (self) => {
      //   When scrolling up, add a gradient so logo is visible.
      //  Otherwise, remove gradient.
      //  Also, either play the y position (forwards reveal) or 
      //  reverse it (backwards up -100%)
      if (self.direction === -1) {
        showAnim.play();
        // nav.classList.add('bg-gradient-to-b','from-black');
      } else {
        showAnim.reverse();
        // nav.classList.remove('bg-gradient-to-b','from-black');
      }
    }
  });
}

//---------------------------------------------
// Actually toggles what carousel displays based on
// toggle switch state
//---------------------------------------------
function toggleViewToMobileOrDesktop(e, isToggledToMobile) {
  viewToggleSwitchContainer.parentNode.removeChild(viewToggleSwitchContainer);
  if (isToggledToMobile) {
    for (const item of desktopContent) {
      // fadeOut(item, 5000);
      item.style.display = "none";
    }
    for (const item of mobileContent) {
      // fadeIn(item, 5000);
      item.style.display = "block"; 
    } 
    document
      .querySelector('[data-bs-target="#offcanvasMenuMobile"]')
      .after(viewToggleSwitchContainer);
    // document.querySelector("#current-view-label").textContent = "Mobile";
  } else {
    for (const item of mobileContent) {
      // fadeOut(item, 5000);
      item.style.display = "none";
    }
    for (const item of desktopContent) {
      // fadeIn(item, 5000);
      item.style.display = "block";
    }
    document
      .querySelector('[data-bs-target="#offcanvasMenuDesktop"]')
      .after(viewToggleSwitchContainer);
    // document.querySelector("#current-view-label").textContent = "Desktop";
  }
  const inputs = document.querySelectorAll('input[type="radio"]');
  inputs.forEach((input) => input.parentElement.nextElementSibling.classList.toggle("text-light-grey"));
  inputs.forEach((input) => input.parentElement.nextElementSibling.classList.toggle("opacity-100"));
}

//---------------------------------------------
// Adjust the navigation indicator .active styles
// when carousel navs to a new slide
// This also updates the thumbnail navigation buttons
//---------------------------------------------
function updateActiveSlideIndicator() {
  let activeSlideIndexDesktop;
  let activeSlideIndexMobile;
  slideElsArrayDesktop.findIndex((element, index) => {
    if (element.classList.contains("active")) {
      activeSlideIndexDesktop = index;
    }
  });
  slideElsArrayMobile.findIndex((element, index) => {
    if (element.classList.contains("active")) {
      activeSlideIndexMobile = index;
    }
  });
  if (activeSlideIndexDesktop != undefined) {
    for (let i = 0; i < slideIndicatorBtnsDesktop.length; i++) {
      slideIndicatorBtnsDesktop[i].classList.remove("active");
      thumbnailNavBtnsDesktop[i].classList.remove("active");
      slideIndicatorBtnsDesktop[i].attributes["ariaCurrent"] = "false";
      thumbnailNavBtnsDesktop[i].attributes["ariaCurrent"] = "false";
      slideIndicatorBtnsDesktop[i].ariaCurrent = "false";
      thumbnailNavBtnsDesktop[i].ariaCurrent = "false";
    }
    slideIndicatorBtnsDesktop[activeSlideIndexDesktop].classList.add("active");
    thumbnailNavBtnsDesktop[activeSlideIndexDesktop].classList.add("active");

    slideIndicatorBtnsDesktop[activeSlideIndexDesktop].attributes[
      "ariaCurrent"
    ] = "true";
    thumbnailNavBtnsDesktop[activeSlideIndexDesktop].attributes["ariaCurrent"] =
      "true";
    slideIndicatorBtnsDesktop[activeSlideIndexDesktop].ariaCurrent = "true";
    thumbnailNavBtnsDesktop[activeSlideIndexDesktop].ariaCurrent = "true";
  }
  if (activeSlideIndexMobile != undefined) {
    for (let i = 0; i < slideIndicatorBtnsMobile.length; i++) {
      slideIndicatorBtnsMobile[i].classList.remove("active");
      thumbnailNavBtnsMobile[i].classList.remove("active");
      slideIndicatorBtnsMobile[i].attributes["ariaCurrent"] = "false";
      thumbnailNavBtnsMobile[i].attributes["ariaCurrent"] = "false";
      slideIndicatorBtnsMobile[i].ariaCurrent = "false";
      thumbnailNavBtnsMobile[i].ariaCurrent = "false";
    }
    slideIndicatorBtnsMobile[activeSlideIndexMobile].classList.add("active");
    thumbnailNavBtnsMobile[activeSlideIndexMobile].classList.add("active");

    slideIndicatorBtnsMobile[activeSlideIndexMobile].attributes["ariaCurrent"] =
      "true";
    thumbnailNavBtnsMobile[activeSlideIndexMobile].attributes["ariaCurrent"] =
      "true";
    slideIndicatorBtnsMobile[activeSlideIndexMobile].ariaCurrent = "true";
    thumbnailNavBtnsMobile[activeSlideIndexMobile].ariaCurrent = "true";
  }
}

//---------------------------------------------
// Gets the dimensions of viewport
//---------------------------------------------
function getDimensions() {
  if (window.innerWidth <= 991) {
    // $("body").addClass("mobile").removeClass("desktop");
    bodyEl.classList.toggle("mobile");
    bodyEl.classList.toggle("desktop");
    mobile_check = true;
  } else {
    mobile_check = false;
    // $("body").addClass("desktop").removeClass("mobile");
    bodyEl.classList.toggle("desktop");
    bodyEl.classList.toggle("mobile");
  }
}

//---------------------------------------------
// Javascript equivalent to $(document).ready();
//---------------------------------------------
function docReady(fn) {
  // see if DOM is already available
  if (
    document.readyState === "complete" ||
    document.readyState === "interactive"
  ) {
    // call on next available tick
    setTimeout(fn, 1);
  } else {
    document.addEventListener("DOMContentLoaded", fn);
  }
}

//---------------------------------------------
// Fade in using pure JS
//---------------------------------------------
function fadeIn(elem, ms) {
  if (!elem) return;

  elem.style.opacity = 0;
  elem.style.filter = "alpha(opacity=0)";
  elem.style.display = "block";
  elem.style.visibility = "visible";

  if (ms) {
    var opacity = 0;
    var timer = setInterval(function () {
      opacity += 50 / ms;
      if (opacity >= 1) {
        clearInterval(timer);
        opacity = 1;
      }
      elem.style.opacity = opacity;
      elem.style.filter = "alpha(opacity=" + opacity * 100 + ")";
    }, 50);
  } else {
    elem.style.opacity = 1;
    elem.style.filter = "alpha(opacity=1)";
  }
}

//---------------------------------------------
// Fade out using pure JS
//---------------------------------------------
function fadeOut(elem, ms) {
  if (!elem) return;

  if (ms) {
    let opacity = 1;
    let timer = setInterval(function () {
      opacity -= 50 / ms;
      if (opacity <= 0) {
        clearInterval(timer);
        opacity = 0;
        elem.style.display = "none";
        elem.style.visibility = "hidden";
      }
      elem.style.opacity = opacity;
      elem.style.filter = "alpha(opacity=" + opacity * 100 + ")";
    }, 50);
  } else {
    elem.style.opacity = 0;
    elem.style.filter = "alpha(opacity=0)";
    elem.style.display = "none";
    elem.style.visibility = "hidden";
  }
}

//---------------------------------------------
// Revise slide index to begin at 0 zero instead of 1
// to resolve conflict of Channel Images not having a :index var param
// and the bs-carousel beginning with indicators at zero 0
// Also update the indeces of the thumbnail navigation in the same way
//---------------------------------------------
function reviseNavigationIndeces() {
  for (let i = 0; i < slideIndicatorBtnsDesktop.length; i++) {
    slideIndicatorBtnsDesktop[i].dataset["bsSlideTo"] -= 1;
    thumbnailNavBtnsDesktop[i].dataset["bsSlideTo"] -= 1;
  }
  for (let i = 0; i < slideIndicatorBtnsMobile.length; i++) {
    slideIndicatorBtnsMobile[i].dataset["bsSlideTo"] -= 1;
    thumbnailNavBtnsMobile[i].dataset["bsSlideTo"] -= 1;
  }
}

if (window.location.pathname != "/") {
  const carouselObjDesktop = new bootstrap.Carousel(myCarouselElDesktop, {
    interval: false,
  });
  const carouselObjMobile = new bootstrap.Carousel(myCarouselElMobile, {
    interval: false,
  });
  const offCanvasMenuObjDesktop = new bootstrap.Offcanvas(offCanvasMenuDesktop);
  const offCanvasMenuObjMobile = new bootstrap.Offcanvas(offCanvasMenuMobile);

  //---------------------------------------------
  // Hide the offcanvas menu if it's open when carousel
  // navs to a new slide
  //---------------------------------------------
  function hideOffcanvasMenu() {
    offCanvasMenuObjDesktop.hide();
    offCanvasMenuObjMobile.hide();
  }

  // On slide change, update active slide indicator
  // since we modified the location of the indicators from
  // BS default location
  myCarouselElDesktop.addEventListener("slid.bs.carousel", function () {
    updateActiveSlideIndicator();
  });
  myCarouselElDesktop.addEventListener("slide.bs.carousel", function () {
    hideOffcanvasMenu();
  });
  myCarouselElMobile.addEventListener("slid.bs.carousel", function () {
    updateActiveSlideIndicator();
  });
  myCarouselElMobile.addEventListener("slide.bs.carousel", function () {
    hideOffcanvasMenu();
  });
}

let slideElsDesktop = document.querySelectorAll(
  "#carouselImagesDesktop .carousel-item"
);
let slideElsArrayDesktop = Array.from(slideElsDesktop);

let slideElsMobile = document.querySelectorAll(
  "#carouselImagesMobile .carousel-item"
);
let slideElsArrayMobile = Array.from(slideElsMobile);

//---------------------------------------------
// Executes all contents on window resize
//---------------------------------------------
window.addEventListener("resize", function () {
  clearTimeout(timeout);
  timeout = setTimeout(getDimensions, delay);
});

//---------------------------------------------
// Document loaded
//---------------------------------------------
docReady(function () {
  for (let i = 0; i < slideIndicatorBtnsDesktop.length; i++) {
    let textForIndicatorBtnDesktop =
      slideIndicatorBtnsDesktop[i].dataset["bsSlideTo"];
    slideIndicatorBtnsDesktop[i].innerText = textForIndicatorBtnDesktop;
  }

  for (let i = 0; i < slideIndicatorBtnsMobile.length; i++) {
    let textForIndicatorBtnMobile =
      slideIndicatorBtnsMobile[i].dataset["bsSlideTo"];
    slideIndicatorBtnsMobile[i].innerText = textForIndicatorBtnMobile;
  }

  // fade out loading gif
  setTimeout(function () {
    const loadingGif = document.querySelector(".loading-gif");
    fadeOut(loadingGif, 400);
  }, 1000);

  // Get window dimensions
  getDimensions();

  reviseNavigationIndeces();

  //---------------------------------------------
  // Listens for toggle switch for Mobile/Desktop view switch
  //---------------------------------------------
  if (viewToggleSwitches) {
    const radios = document.getElementsByName('radio');
    let checkedRadio;

    radios.forEach(function(radioInput) {
      if(radioInput.checked) {
        checkedRadio = radioInput;
        return;
      }
    });

    viewToggleSwitches.forEach(function(toggleSwitch) {
      toggleSwitch.addEventListener("change", function (e) {
        isToggledToMobile = !isToggledToMobile;
        toggleViewToMobileOrDesktop(e, isToggledToMobile);
      });
    });
  }

  initNavAnimations();

});
